import {isNotNullOrUndefined} from "~/utils/isNotNullOrUndefined"
import {dateTime} from "~/utils/dateTime"
import clsx from "clsx"

export type WorkExperienceItemProps = {
    workExperience: {
        companyLogo: {
            url: string
        }
        companyName: string
        position: string
        description?: string | null
        startDate?: string
        endDate?: string | null
    }
}

export const WorkExperienceItem = (props: WorkExperienceItemProps) => {

    const {workExperience} = props

    return (
        <details
            className={clsx(
                "mt-4 flex flex-col",
                "sm:rounded-lg",
                isNotNullOrUndefined(workExperience.description) ? "transition-all hover:bg-gray-50 cursor-pointer" : ""
            )}
        >
            <summary
                className="p-4 flex items-center">
                <div
                    className="flex-shrink-0 p-1 rounded-full shadow-md"
                >
                    <img
                        className="w-9 h-9 rounded-full"
                        src={workExperience.companyLogo.url}
                        alt={workExperience.companyName}
                    />
                </div>
                <div className="ml-6 flex-grow flex">
                    <div className="mr-2 flex-grow flex flex-col">
                        <h3 className="font-semibold">
                            {workExperience.position}
                        </h3>
                        <h4 className="text-sm text-gray-600 font-medium">
                            {workExperience.companyName}
                        </h4>
                    </div>
                    {isNotNullOrUndefined(workExperience.startDate) && (
                        <p className="flex-shrink-0 text-sm text-gray-600 text-end">
                            {dateTime(workExperience.startDate).toLocaleString({year: "numeric"})}
                            {" - "}
                            {isNotNullOrUndefined(workExperience.endDate) ? (dateTime(workExperience.endDate).toLocaleString({year: "numeric"})) : "now"}
                        </p>
                    )}
                </div>
            </summary>
            {isNotNullOrUndefined(workExperience.description) && (
                <p className={clsx(
                    "mt-1 px-4 pb-6 text-sm text-gray-600 whitespace-pre-wrap",
                    "sm:ml-14 sm:pl-7",
                    "lg:mr-20 lg:pr-4"
                )}>
                    {workExperience.description}
                </p>
            )}
        </details>
    )
}