import type {IconProps} from "~/components/icons/IconProps"
import React from "react"

export const LinkedInIcon = (props: IconProps) => {
    return (
        <svg
            className={props.className ?? "w-10 h-10"}
            viewBox="0 0 24 24"
            aria-hidden="true"
            fill="currentColor"
        >
            <path
                d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 01-1.548-1.549 1.548 1.548 0 111.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"
            />
        </svg>
    )
}