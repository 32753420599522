import clsx from "clsx"
import {PageLayout} from "~/components/PageLayout"
import {json} from "@remix-run/node"
import {GetWorkExperienceQuery} from "~/.server/workExperience/getWorkExperienceQuery.server"
import {by} from "~/utils/by"
import {WorkExperienceItem} from "~/components/WorkExperienceItem"
import {TwitterIcon} from "~/components/icons/TwitterIcon"
import {InstagramIcon} from "~/components/icons/InstagramIcon"
import {GithubIcon} from "~/components/icons/GithubIcon"
import {LinkedInIcon} from "~/components/icons/LinkedInIcon"
import {EmailIcon} from "~/components/icons/EmailIcon"
import {fetchFromGraphQL} from "~/.server/utils/fetchFromGraphQL.server"
import {useCachedLoaderData} from "remix-client-cache"

export async function loader() {

  const workExperienceResponse = await fetchFromGraphQL(GetWorkExperienceQuery)

  return json({
    workExperiences: workExperienceResponse.data?.workExperiences,
    errors: workExperienceResponse.errors
  })
}

export default function Index() {

  const {workExperiences} = useCachedLoaderData<typeof loader>()

  return (
    <PageLayout>
      <div className="mt-20 flex flex-col items-start lg:flex-row">
        <div
          className={clsx(
            "mx-auto flex-shrink-0 w-40 h-40 aspect-square p-2 rounded-full shadow-xl",
            "lg:mr-20 lg:h-48 lg:w-48"
          )}
        >
          <img
            className={clsx(
              "aspect-square object-cover rounded-full border-3 border-white"
            )}
            src="https://media.graphassets.com/kdEmWUd5RDioMsP9zSri"
            alt="Avatar"
          />
        </div>
        <div
          className={clsx(
            "flex flex-col"
          )}
        >
          <div
            className={clsx(
              "flex flex-col items-center",
              "lg:flex-row-reverse lg:items-center lg:justify-center"
            )}
          >
            <div
              className={clsx(
                "mt-10 flex-grow flex flex-col items-center",
                "lg:mt-auto lg:mb-auto lg:items-start"
              )}
            >
              <h1
                className={clsx(
                  "font-display text-5xl text-black text-center",
                  "md:text-6xl",
                  "lg:text-left"
                )}
              >
                Ben De La Haye
              </h1>
              <h2
                className={clsx(
                  "mt-2 text-black/90 text-center",
                  "xs:text-md",
                  "sm:text-lg",
                  "lg:text-xl lg:text-left"
                )}
              >
                Product Engineer and Startup Founder
              </h2>
            </div>
          </div>
          <div
            className={clsx(
              "mt-3 flex items-center justify-center",
              "lg:-ml-1 lg:justify-start"
            )}
          >
            <a
              className="mr-2 p-2 rounded-full hover:bg-gray-50"
              href="https://twitter.com/FuriousBenD"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon
                className="w-6 h-6 text-gray-800"
              />
            </a>
            <a
              className="mr-2 p-2 rounded-full hover:bg-gray-50"
              href="https://www.instagram.com/ben_dlh/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon
                className="w-6 h-6 text-gray-800"
              />
            </a>
            <a
              className="mr-2 p-2 rounded-full hover:bg-gray-50"
              href="https://github.com/BenDLH"
              target="_blank"
              rel="noreferrer"
            >
              <GithubIcon
                className="w-6 h-6 text-gray-800"
              />
            </a>
            <a
              className="mr-2 p-2 rounded-full hover:bg-gray-50"
              href="https://www.linkedin.com/in/bendlh/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon
                className="w-6 h-6 text-gray-800"
              />
            </a>
            <a
              className="mr-2 p-2 rounded-full hover:bg-gray-50"
              href="mailto:bdlh1993@gmail.com"
            >
              <EmailIcon
                className="w-6 h-6 text-gray-800"
              />
            </a>
          </div>
          <p
            className={clsx(
              "mt-8"
            )}
          >
            Hi there! I&apos;m a passionately curious guy that loves to solve problems with digital
            solutions. A hacker, builder and tinkerer by nature, I&apos;m always learning something new.
            <br/>
            <br/>
            For a decade I&apos;ve been coding, designing and launching digital
            products across a broad range of industries. I&apos;m based in
            Copenhagen, Denmark.
          </p>
        </div>
      </div>
      <h2
        className={clsx(
          "mt-12 text-2xl font-semibold",
          "lg:mt-24"
        )}
      >
        Work
      </h2>
      <div className="mt-2 -mx-4 sm:mx-0 flex flex-col">
        {(workExperiences ?? [])
          .sort(by(workExperience => workExperience.startDate, true))
          .map((workExperience) => {
            return (
              <WorkExperienceItem
                key={workExperience.id}
                workExperience={workExperience}
              />
            )
          })}
      </div>
    </PageLayout>
  )
}